import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    token: String
  }

  kickoff() {
    const jsonContainer = this.element.querySelector("#jsonContent")
    const responseContainer = this.element.querySelector("#responseContent")
    let jsonContent = this.getJsonContent(jsonContainer)

    try {
      jsonContent = JSON.parse(jsonContent)
    } catch (error) {
      alert("Invalid JSON content. Please correct it and try again.")
      return
    }

    if (jsonContent["stepWebhookUrl"] === "") {
      delete jsonContent["stepWebhookUrl"]
    }
    if (jsonContent["taskWebhookUrl"] === "") {
      delete jsonContent["taskWebhookUrl"]
    }
    if (jsonContent["crewWebhookUrl"] === "") {
      delete jsonContent["crewWebhookUrl"]
    }

    fetch(this.urlValue + '/kickoff', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.tokenValue}`
      },
      body: JSON.stringify(jsonContent)
    })
    .then(response => {
      const status = response.status
      return response.json().then(data => ({ status, data }))
    })
    .then(({ status, data }) => {
      console.log("Success:", data)
      responseContainer.innerHTML = `
<span class="text-green-500"><strong>Status:</strong> ${status}</span>
<span class="text-green-500"><strong>Success:</strong> ${status >= 200 && status < 300}</span>
<span><strong>Data:</strong></span>
<pre class="h-auto">${JSON.stringify(data, null, 2)}</pre>
      `
    })
    .catch(error => {
      console.error("Error:", error)
      responseContainer.innerHTML = `<span class="text-primary-color">Error: ${error}</span>`
    })
  }

  fetchStatus() {
    const taskId = this.element.querySelector("#taskIdInput").value
    const statusResponseContainer = this.element.querySelector("#statusResponseContent")

    if (!taskId) {
      alert("Please enter a Task ID.")
      return
    }

    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    if (!uuidPattern.test(taskId)) {
      alert("Please enter a valid UUID for the Task ID.")
      return
    }

    fetch(`${this.urlValue}/status/${taskId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.tokenValue}`
      }
    })
    .then(response => {
      const status = response.status
      return response.json().then(data => ({ status, data }))
    })
    .then(({ status, data }) => {
      console.log("Success:", data)
      statusResponseContainer.innerHTML = `
<span class="text-green-500"><strong>Status:</strong> ${status}</span>
<span class="text-green-500"><strong>Success:</strong> ${status >= 200 && status < 300}</span>
<span class="text-green-500"><strong>Data:</strong></span>
<pre class="whitespace-pre-wrap w-full language-json">${JSON.stringify(data, null, 2)}</pre>
      `
    })
    .catch(error => {
      console.error("Error:", error)
      statusResponseContainer.innerHTML = `<span class="text-primary-color">Error: ${error}</span>`
    })
  }

  input() {
    const responseContainer = this.element.querySelector("#inputResponseContent")

    fetch(`${this.urlValue}/inputs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.tokenValue}`
      }
    })
    .then(response => {
      const status = response.status
      return response.json().then(data => ({ status, data }))
    })
    .then(({ status, data }) => {
      console.log("Success:", data)
      responseContainer.innerHTML = `
<span class="text-green-500"><strong>Status:</strong> ${status}</span>
<span class="text-green-500"><strong>Success:</strong> ${status >= 200 && status < 300}</span>
<span><strong>Data:</strong></span>
<pre class="h-auto">${JSON.stringify(data, null, 2)}</pre>
      `
    })
    .catch(error => {
      console.error("Error:", error)
      responseContainer.innerHTML = `<span class="text-primary-color">Error: ${error}</span>`
    })
  }

  getJsonContent(container) {
    const spans = container.querySelectorAll("span[contenteditable='true']")
    spans.forEach(span => {
      const key = span.previousElementSibling.textContent.replace(/[:"]/g, "").trim()
      if (key) {
        span.textContent = `${span.textContent.trim()}`
      }
    })
    return container.textContent
  }
}
