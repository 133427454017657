import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "instanceSelect", "spinner"]
  static values = { deploymentId: String }

  connect() {
    this.loadDeploymentInstances()
    this.loadCrewExecutions()
  }

  loadDeploymentInstances() {
    const deploymentId = this.deploymentIdValue
    fetch(`/crewai_plus/deployments/${deploymentId}/deployment_instances`)
      .then(response => response.json())
      .then(instances => {
        this.instanceSelectTarget.innerHTML = instances.map(instance =>
          `<option value="${instance.id}">${new Date(instance.created_at).toLocaleString()}</option>`
        ).join('')
      })
      .catch(error => console.error("Error loading deployment instances:", error))
  }

  loadCrewExecutions(event) {
    if (event) event.preventDefault()
    const deploymentId = this.deploymentIdValue
    const instanceId = this.instanceSelectTarget.value
    let url = `/crewai_plus/deployments/${deploymentId}/crew_executions?deployment_instance_id=${instanceId}`

    if (event && event.currentTarget.href) {
      url = event.currentTarget.href
    }

    this.showSpinner()

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.containerTarget.innerHTML = html
      })
      .catch(error => console.error("Error loading crew executions:", error))
      .finally(() => this.hideSpinner())
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
    this.containerTarget.style.opacity = '0.3'
    this.containerTarget.style.pointerEvents = 'none'
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
    this.containerTarget.style.opacity = '1'
    this.containerTarget.style.pointerEvents = 'auto'
  }
}
