import React, { useCallback, useEffect, useState } from 'react';
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from '@xyflow/react';
import UICrewTaskNode from './UICrewTaskNode';
import UICrewConnectionLine from './UICrewConnectionLine';
import { UICrewAnimatedEdge } from './UICrewAnimatedEdge';
import UICrewTaskModal from './UICrewTaskModal';

const nodeTypes = {
  taskNode: UICrewTaskNode,
};

const edgeTypes = {
  animated: UICrewAnimatedEdge,
};

function UiCrewNodes({ crew }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [availableTools, setAvailableTools] = useState([]);

  const handleNodeClick = useCallback((taskData) => {
    setSelectedTask(taskData);
  }, []);

  const handleAgentChange = useCallback((taskId, newAgent) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === taskId
          ? {
              ...node,
              data: {
                ...node.data,
                agent: newAgent,
                tools: crew.agents.find(agent => agent.name === newAgent).tools || []
              }
            }
          : node
      )
    );
    setSelectedTask((prevTask) =>
      prevTask && prevTask.id === taskId
        ? {
            ...prevTask,
            agent: newAgent,
            tools: crew.agents.find(agent => agent.name === newAgent).tools || []
          }
        : prevTask
    );
  }, [crew.agents]);

  const handleToolsChange = useCallback((taskId, newTools) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === taskId
          ? { ...node, data: { ...node.data, tools: newTools } }
          : node
      )
    );
    setSelectedTask((prevTask) =>
      prevTask && prevTask.id === taskId
        ? { ...prevTask, tools: newTools }
        : prevTask
    );
  }, []);

  useEffect(() => {
    if (crew) {
      const allTools = new Set();
      crew.agents.forEach(agent => {
        agent.tools.forEach(tool => allTools.add(tool));
      });
      setAvailableTools(Array.from(allTools));

      const newNodes = [];
      const newEdges = [];

      // Task nodes in sequence
      crew.tasks.forEach((task, index) => {
        newNodes.push({
          id: `task-${index}`,
          type: 'taskNode',
          position: { x: (index + 1) * 300, y: 0 },
          data: {
            id: `task-${index}`,
            description: task.description,
            agent: task.agent,
            expected_output: task.expected_output,
            agents: crew.agents,
            tools: task.tools || availableTools || [],
            onClick: handleNodeClick,
          },
        });

        // Connect crew to first task
        if (index === 0) {
          newEdges.push({
            id: `crew-task-${index}`,
            source: 'crew',
            target: `task-${index}`,
            type: 'animated',
          });
        }

        // Connect tasks sequentially
        if (index > 0) {
          newEdges.push({
            id: `task-${index - 1}-task-${index}`,
            source: `task-${index - 1}`,
            target: `task-${index}`,
            type: 'animated',
          });
        }
      });

      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [crew, handleNodeClick]);

  const onConnect = useCallback(
    (params) => {
      // Check if the connection is valid (e.g., not connecting a node to itself)
      if (params.source !== params.target) {
        setEdges((eds) => addEdge({ ...params, type: 'animated' }, eds));
      }
    },
    [setEdges]
  );

  const onEdgesDelete = useCallback(
    (edgesToDelete) => setEdges((eds) => eds.filter((edge) => !edgesToDelete.some((e) => e.id === edge.id))),
    [setEdges]
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgesDelete={onEdgesDelete}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineComponent={UICrewConnectionLine}
        connectionLineType="smoothstep"
        fitView
        nodesDraggable={true}
        nodesConnectable={true}
        elementsSelectable={true}
        edgesFocusable={true}
        selectNodesOnDrag={false}
        connectionMode="loose" // This allows more flexible connections
      >
        <Controls />
        <MiniMap />
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
      {selectedTask && (
        <UICrewTaskModal
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
          onAgentChange={handleAgentChange}
          onToolsChange={handleToolsChange}
          availableAgents={crew.agents}
          availableTools={availableTools}
        />
      )}
    </div>
  );
}

export default UiCrewNodes;