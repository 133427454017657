import React from 'react';
import PropTypes from 'prop-types';

const Message = ({ message, aiAvatarPath }) => {
  return (
    <div className={`flex items-start p-4 my-2 ${message.sender === 'ai' ? 'bg-gray-100' : 'bg-primary-color-lighter'} rounded-lg ${message.sender === 'user' ? 'justify-end' : ''}`}>
      {message.sender === 'ai' && (
        <div className="flex-shrink-0">
          <img className="h-10 w-10 mr-3 rounded-full" src={aiAvatarPath} alt="Crew Assistant Avatar" />
        </div>
      )}
      <div className={`${message.sender === 'user' ? 'text-right' : ''}`}>
        <div className="text-sm font-bold text-gray-900">{message.sender === 'ai' ? 'Crew Assistant' : 'You'}</div>
        <div className={`mt-1 text-sm text-gray-700`}>
          {message.sender === 'ai' ? (
            <div dangerouslySetInnerHTML={{ __html: message.html }} />
          ) : (
            message.text && message.text.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                {i < message.text.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string,
    sender: PropTypes.oneOf(['ai', 'user']).isRequired,
  }).isRequired,
};

export default Message;