import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="deployment-show"
export default class extends Controller {
  static values = { id: Number, url: String, token: String };
  static targets = ["tab", "content", "trainResponse", "editButton", "viewMode", "editMode", "editArea"];

  connect() {
    this.showTabFromAnchor()
    window.addEventListener('hashchange', this.showTabFromAnchor.bind(this))
  }

  disconnect() {
    window.removeEventListener('hashchange', this.showTabFromAnchor.bind(this))
  }

  showTabFromAnchor() {
    const anchor = window.location.hash.slice(1)
    if (anchor) {
      const tab = this.tabTargets.find(t => t.getAttribute('href') === `#${anchor}`)
      if (tab) {
        this.show({ target: tab })
      }
    } else {
      // If no anchor, show the first tab
      this.show({ target: this.tabTargets[0] })
    }
  }

  trainCrew(event) {
    event.preventDefault();
    const trainButton = event.currentTarget;

    // Collect input values
    const inputs = {};
    let hasError = false;
    document.querySelectorAll('#training-inputs input').forEach(input => {
      if (!input.value.trim()) {
        this.showErrorMessage(input, `${input.name} is required`);
        hasError = true;
      } else {
        inputs[input.name] = input.value.trim();
        this.clearErrorMessage(input);
      }
    });

    if (hasError) {
      return; // Don't proceed if there are errors
    }

    // Immediately update button appearance
    this.updateTrainButton(trainButton, true);

    const url = `/crewai_plus/deployments/${this.idValue}/train_crew`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      },
      body: JSON.stringify({ inputs })
    })
      .then(response => response.json())
      .then(data => {
        if (data.task_id) {
          // Refresh the page if task_id is returned
          window.location.reload();
        } else {
          this.updateTrainButton(trainButton, false);
          this.showErrorMessage(trainButton, `Error: ${data.error || 'Unknown data error'}`);
        }
      })
      .catch(error => {
        this.updateTrainButton(trainButton, false);
        this.showErrorMessage(trainButton, `Error: ${error.message || 'Unknown error'}`);
      });
  }

  updateTrainButton(button, isLoading) {
    button.disabled = isLoading;
    button.textContent = isLoading ? 'Training...' : 'Train Crew';
    button.classList.toggle('bg-gray-400', isLoading);
    button.classList.toggle('cursor-not-allowed', isLoading);
    button.classList.toggle('bg-primary-color', !isLoading);
    button.classList.toggle('hover:bg-primary-color', !isLoading);
  }

  showErrorMessage(element, errorText) {
    this.clearErrorMessage(element);
    const message = document.createElement('span');
    message.textContent = errorText;
    message.classList.add('ml-2', 'text-primary-color', 'error-message');
    element.parentNode.insertBefore(message, element.nextSibling);
  }

  clearErrorMessage(element) {
    const errorMessage = element.parentNode.querySelector('.error-message');
    if (errorMessage) {
      errorMessage.remove();
    }
  }

  show(event) {
    this.tabTargets.forEach((tab) => {
      const content = document.querySelector(tab.getAttribute("href"))
      if (tab === event.target) {
        tab.classList.add("active-tab")
        content.classList.remove("hidden")
      } else {
        tab.classList.remove("active-tab")
        content.classList.add("hidden")
      }
    })

    // Update the URL without triggering a page reload
    history.pushState(null, '', event.target.getAttribute("href"))
  }

  copyUrl(event) {
    event.preventDefault();
    if (!navigator.clipboard) {
      console.error("Clipboard API not available.");
      return;
    }

    navigator.clipboard.writeText(this.urlValue)
      .then(() => {
        const popup = this.element.querySelector(`#copy-confirmation-${this.idValue}`);
        if (popup) {
          // Show tooltip
          popup.classList.remove('opacity-0', 'translate-y-2');
          popup.classList.add('opacity-100', 'translate-y-0');

          // Hide tooltip after 2 seconds
          setTimeout(() => {
            popup.classList.remove('opacity-100', 'translate-y-0');
            popup.classList.add('opacity-0', 'translate-y-2');
          }, 1500);
        }
      })
      .catch(err => {
        console.error('Failed to copy URL: ', err);
      });
  }

  copyToken(event) {
    event.preventDefault();
    if (!navigator.clipboard) {
      console.error("Clipboard API not available.");
      return;
    }

    navigator.clipboard.writeText(this.tokenValue)
      .then(() => {
        const popup = this.element.querySelector(`#copy-token-confirmation-${this.idValue}`);
        if (popup) {
          // Show tooltip
          popup.classList.remove('opacity-0', 'translate-y-2');
          popup.classList.add('opacity-100', 'translate-y-0');

          // Hide tooltip after 2 seconds
          setTimeout(() => {
            popup.classList.remove('opacity-100', 'translate-y-0');
            popup.classList.add('opacity-0', 'translate-y-2');
          }, 1500);
        }
      })
      .catch(err => {
        console.error('Failed to copy URL: ', err);
      });
  }

  toggleEdit(event) {
    const roleId = event.currentTarget.closest('[data-role-id]').dataset.roleId;
    this.viewModeTargets.find(t => t.dataset.roleId === roleId).classList.toggle("hidden");
    this.editModeTargets.find(t => t.dataset.roleId === roleId).classList.toggle("hidden");
    this.editButtonTargets.find(t => t.dataset.roleId === roleId).classList.toggle("hidden");
  }

  saveSuggestions(event) {
    const roleElement = event.currentTarget.closest('[data-role-id]');
    const roleId = roleElement.dataset.roleId;
    const filenameElement = document.getElementById('filename');
    const filename = filenameElement ? filenameElement.textContent.trim() : null;
    const suggestions = this.editAreaTargets.find(t => t.dataset.roleId === roleId).value.split("\n").filter(s => s.trim() !== "");

    fetch(`/crewai_plus/deployments/${this.idValue}/update_suggestions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        role_id: roleId,
        suggestions: suggestions,
        filename: filename
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.updateSuggestionsList(roleId, suggestions);
        this.toggleEdit({ currentTarget: this.editButtonTargets.find(t => t.dataset.roleId === roleId) });
      } else {
        console.error('Failed to update suggestions');
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  updateSuggestionsList(roleId, suggestions) {
    const ul = this.viewModeTargets.find(t => t.dataset.roleId === roleId).querySelector('ul');
    ul.innerHTML = suggestions.map(s => `<li>${s}</li>`).join('');
  }
}
