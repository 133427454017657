import React from 'react';
import Select from 'react-select';

const UICrewTaskModal = ({
  task,
  onClose,
  onAgentChange,
  onToolsChange,
  onDescriptionChange,
  onExpectedOutputChange,
  availableAgents,
  availableTools
}) => {
  return (
    <div className="task-modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" onClick={onClose}>
      <div className="task-modal-content bg-white rounded-lg p-8 max-w-md w-full shadow-xl" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-2xl font-bold m-0 mb-2">Task Details</h2>
        <div>
          <div className="mb-1">
            <span className="font-semibold text-sm">Description:</span>
            <textarea
              className="text-xs block w-full mt-1 p-2 border rounded focus:border-primary-color focus:ring focus:ring-primary-color-light focus:ring-opacity-50"
              value={task.description}
              onChange={(e) => onDescriptionChange(task.id, e.target.value)}
            />
          </div>
          <div className="mb-1">
            <span className="font-semibold text-sm">Expected Output:</span>
            <textarea
              className="text-xs block w-full mt-1 p-2 border rounded focus:border-primary-color focus:ring focus:ring-primary-color-light focus:ring-opacity-50"
              value={task.expected_output}
              onChange={(e) => onExpectedOutputChange(task.id, e.target.value)}
            />
          </div>
          <div className="mb-1">
            <span className="font-semibold text-sm">Agent:</span>
            <select
              className="text-xs block w-full mt-1 p-2 border rounded focus:border-primary-color focus:ring focus:ring-primary-color-light focus:ring-opacity-50"
              value={task.agent}
              onChange={(e) => onAgentChange(task.id, e.target.value)}
            >
              {availableAgents.map((agent) => (
                <option key={agent.role} value={agent.role}>
                  {agent.role}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-1">
            <span className="font-semibold text-sm">Tools:</span>
            <Select
              isMulti
              options={availableTools.map(tool => ({ value: tool, label: tool }))}
              value={task.tools.map(tool => ({ value: tool, label: tool }))}
              onChange={(selectedOptions) => {
                const selectedTools = selectedOptions.map(option => option.value);
                onToolsChange(task.id, selectedTools);
              }}
              className="text-xs mt-1"
              classNamePrefix="react-select"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UICrewTaskModal;