import React, { memo } from 'react';
import { Handle, Position } from '@xyflow/react';

const UICrewTaskNode = memo(({ data, isConnectable }) => {
  return (
    <div className="react-flow__node-taskNode gradient" onClick={() => data.onClick(data)}>
      <Handle
        type="target"
        position={Position.Top}
        style={{
          background: '#555',
          width: '30px',
          height: '30px',
          top: '-15px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: -1,
          borderRadius: '25%',
        }}
        isConnectable={isConnectable}
      />
      <div className="wrapper">
        <div className="inner">
          <div className="task-content">
            <h4 className='m-0'>Task</h4>
            <div className="mb-2">
              {data.description}
            </div>
            <div className="">
              <b>Agent:</b> {data.agent}
            </div>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          background: '#555',
          width: '30px',
          height: '30px',
          bottom: '-15px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: -1,
          borderRadius: '25%',
        }}
        isConnectable={isConnectable}
      />
    </div>
  );
});

export default UICrewTaskNode;