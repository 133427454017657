import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { branchesApiUrl: String }

  static targets = [
    "envVars",
    "bulkEnvVarsModal",
    "bulkEnvVarsInput",
    "addEnvVarButton",
    "valueField",
    "repo",
    "branch"
  ];
  index = 0; // Initialize the counter

  connect() {
    this.originalBulkButtonText = this.addEnvVarButtonTarget.textContent;
    this.bulkEnvVarsInputTarget.addEventListener(
      "input",
      this.translateFromBulk.bind(this)
    );
    this.index = this.envVarsTarget.children.length; // Start the index based on existing items

    // Hide all value fields initially
    this.hideValueFields();
  }

  hideValueFields() {
    this.valueFieldTargets.forEach((field) => {
      field.type = "password";
    });
  }

  showValue(event) {
    event.target.type = "text";
  }

  hideValue(event) {
    event.target.type = "password";
  }

  add(event) {
    event.preventDefault();
    let newFields = this.templateContent();
    this.envVarsTarget.insertAdjacentHTML("beforeend", newFields);
  }

  edit_bulk(event) {
    event.preventDefault();
    const isBulkVisible =
      !this.bulkEnvVarsModalTarget.classList.toggle("hidden");
    this.envVarsTarget.classList.toggle("hidden", isBulkVisible);
    this.addEnvVarButtonTarget.classList.toggle("hidden", isBulkVisible);

    this.addEnvVarButtonTarget.textContent = isBulkVisible
      ? "Switch to Single Add"
      : this.originalBulkButtonText;

    if (isBulkVisible) {
      this.translateToBulk();
    } else {
      this.bulkEnvVarsInputTarget.value = "";
    }
  }

  remove(event) {
    event.preventDefault();
    event.target.closest(".environment-variable").remove();
  }

  templateContent() {
    const newIndex = this.index++; // Use and increment the counter
    return `
      <div class="environment-variable items-center my-2 p-2 border rounded-lg border-gray-300 flex gap-x-2">
        <input type="text" name="deployment[environment_variables_attributes][${newIndex}][key]" placeholder="Key" class="text-sm w-full h-9 text-base placeholder-gray-600 border rounded-md appearance-none focus:shadow-outline focus:border-primary-color focus:ring focus:ring-primary-color-light focus:ring-opacity-50">
        <input type="password" name="deployment[environment_variables_attributes][${newIndex}][value]" placeholder="Value (not shown for security)" class="text-sm w-full h-9 text-base placeholder-gray-600 border rounded-md appearance-none focus:shadow-outline focus:border-primary-color focus:ring focus:ring-primary-color-light focus:ring-opacity-50" data-target="deployment-form.valueField" data-action="focus->deployment-form#showValue blur->deployment-form#hideValue">
        <a href="#" class="remove-environment-variable" data-action="click->deployment-form#remove">
          <button class="text-white !bg-primary-color px-4 py-2 w-full rounded-lg text-sm hover:!bg-red-400">
            Remove
          </button>
        </a>
      </div>
    `;
  }

  translateToBulk() {
    const envVars = this.envVarsTarget.querySelectorAll(
      ".environment-variable"
    );
    const bulkValue = Array.from(envVars)
      .map((envVar) => {
        const key = envVar.querySelector('input[name*="[key]"]').value;
        const value = envVar.querySelector('input[name*="[value]"]').value;
        return `${key}=${value}`;
      })
      .join("\n");
    this.bulkEnvVarsInputTarget.value = bulkValue;
  }

  translateFromBulk() {
    const lines = this.bulkEnvVarsInputTarget.value.split("\n");
    const existingEnvVars = this.envVarsTarget.querySelectorAll(
      ".environment-variable"
    );

    lines.forEach((line, index) => {
      const [key, value] = line.split("=").map((part) => part.trim());

      if (existingEnvVars.length > index) {
        // Update existing fields
        const envVar = existingEnvVars[index];
        envVar.querySelector('input[name*="[key]"]').value = key;
        envVar.querySelector('input[name*="[value]"]').value = value;
      } else {
        // Add new fields if there are more lines than existing fields
        this.addEnvVar(key, value);
      }
    });

    // Remove extra fields if there are more existing fields than lines
    if (existingEnvVars.length > lines.length) {
      for (let i = lines.length; i < existingEnvVars.length; i++) {
        existingEnvVars[i].remove();
      }
    }
  }

  // Add or update your addEnvVar method to handle an optional key and value
  addEnvVar(key = "", value = "") {
    const newIndex = this.index++; // Use and increment the counter
    let newFields = `
      <div class="environment-variable items-center my-2 p-2 border rounded-lg border-gray-300 flex gap-x-2">
        <input type="text" value="${key}" name="deployment[environment_variables_attributes][${newIndex}][key]" placeholder="Key" class="text-sm w-full h-9 text-base placeholder-gray-600 border rounded-md appearance-none focus:shadow-outline">
        <input type="password" value="${value}" name="deployment[environment_variables_attributes][${newIndex}][value]" placeholder="Value (not shown for security)" class="text-sm w-full h-9 text-base placeholder-gray-600 border rounded-md appearance-none focus:shadow-outline" data-target="deployment-form.valueField" data-action="focus->deployment-form#showValue blur->deployment-form#hideValue">
        <a href="#" class="remove-environment-variable" data-action="click->deployment-form#remove">
          <button class="text-white !bg-primary-color px-4 py-2 w-full rounded-lg text-sm hover:!bg-red-400">
            Remove
          </button>
        </a>
      </div>
    `;
    this.envVarsTarget.insertAdjacentHTML("beforeend", newFields);
  }

  loadBranches(repoName) {
    const placeholder = '<option value="">Select a branch</option>';

    this.branchTarget.setAttribute("disabled", true);
    this.branchTarget.innerHTML = placeholder;

    const apiUrl = this.branchesApiUrlValue + "?" + new URLSearchParams({repo: repoName});

    fetch(apiUrl)
      .then(response => response.json())
      .then(branches => {
        branches.sort((a, b) => {
          if (a === "main" || a === "master") return -1;
          if (b === "main" || b === "master") return 1;
          return a.localeCompare(b);
        });

        const newOptions = branches.map(branch => `<option value="${branch}">${branch}</option>`).join("");
        this.branchTarget.innerHTML = placeholder + newOptions;
        this.branchTarget.removeAttribute("disabled");
      })
  }

  updateRepo(event) {
    const repoName = event.target.options[event.target.selectedIndex].text;
    this.loadBranches(repoName);
  }
}
